body>iframe {
  pointer-events: none;
}

/* load a font */
@font-face {
  font-family: 'kohinoor';
  /* src: local('kohinoor'), url('/fonts/kohinoor.woff') format('woff'); */
}

/* body {
  font-family: 'kohinoor';
} */

/* image styles start */
.image-default-contained,
.image-default-cover {
  width: 100%;
  height: 100%;
}

.image-default-contained {
  object-fit: contain;
}

.image-default-cover {
  object-fit: cover;
}

.multi-date-picker-wrapper .MuiCalendarPicker-root div {
  overflow-y: hidden;
}

.multi-date-picker-wrapper .MuiCalendarPicker-root {
  box-shadow: 0px 0px 11px 0px #457ccd3b;
  border: 1px solid rgba(0, 0, 0, 0.133);
  border-radius: 4px;
}

.multi-date-picker-wrapper .MuiCalendarPicker-root .Mui-selected {
  border: none;
}

.multi-date-picker-wrapper .MuiCalendarPicker-root>div:first-child>div:first-child div:nth-child(2) {
  display: none;

}

.multi-date-picker-wrapper .MuiCalendarPicker-root>div:first-child>div:first-child>button {
  display: none;
}

/* image styles start */